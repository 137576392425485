export default {
  client: null,
  clientInfo: null,
  clientName: '',
  companyName: '',
  clients: [],
  categories: [],
  languages: [],
  homeActive: 'collaboration',
  showCookiesPolicy: false,
  storedAction: null
}
