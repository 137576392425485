import Vue from 'vue'

export default {
  initClient: ({ commit, getters, rootGetters, dispatch }, client) => {
    return new Promise((resolve, reject) => {
      commit('SET_CLIENT', client)
      if (client) {
        if (!getters.clientInfo) {
          Vue.http.post(`${rootGetters.siteUrl}/sponsor/get_client`, { id_client: getters.client, client_language: rootGetters.language })
            .then(response => {
              commit('SET_BUSY', false, { root: true })
              return response.json()
            }, () => {
              commit('SET_BUSY', false, { root: true })
              reject(new Error('Could not get client!'))
            })
            .then(data => {
              dispatch('initClientData', data)
              resolve(data.client)
            }, () => {
              commit('SET_BUSY', false, { root: true })
              reject(new Error('Could not get client!'))
            })
        } else {
          dispatch('initAction')
          resolve(getters.clientInfo)
        }
      } else {
        reject(new Error('No client ID!'))
      }
    })
  },
  initClientBySlug: ({ commit, getters, rootGetters, dispatch }, slug) => {
    return new Promise((resolve, reject) => {
      if (slug) {
        if (!getters.clientInfo) {
          Vue.http.post(`${rootGetters.siteUrl}/sponsor/get_client`, { slug: slug, client_language: rootGetters.language })
            .then(response => {
              commit('SET_BUSY', false, { root: true })
              return response.json()
            }, () => {
              commit('SET_BUSY', false, { root: true })
              reject(new Error('Could not get client!'))
            })
            .then(data => {
              dispatch('initClientData', data)
              resolve(data.client)
            }, () => {
              commit('SET_BUSY', false, { root: true })
              reject(new Error('Could not get client!'))
            })
        } else {
          dispatch('initAction')
          resolve(getters.clientInfo)
        }
      } else {
        reject(new Error('No client SLUG provided!'))
      }
    })
  },
  initClientData: ({ commit, dispatch }, data) => {
    if (data) {
      commit('SET_CLIENT', data.id_client)
      commit('SET_CURRENT_CLIENT_INFO', data.client)
      commit('SET_NAME', data.client.name)
      commit('SET_LANGUAGE', data.language, { root: true })
      commit('SET_TRANSLATIONS', data.translations, { root: true })
      commit('SET_CATEGORIES', data.menu.options)
      commit('SET_LANGUAGES', data.menu.languages)
      commit('auth/SET_ROLE', data.role, { root: true })
      commit('auth/SET_USER_NAME', data.full_name, { root: true })
      commit('auth/SET_EMAIL', data.email, { root: true })
      commit('auth/SET_INVITED', data.invited, { root: true })
      commit('auth/SET_REGISTERED', data.registered, { root: true })
      commit('SET_EXCLUSIVE', data.exclusive_site, { root: true })
      commit('SET_SLUG', data.client.slug, { root: true })
      commit('SET_SITE_NAME', data.site_name, { root: true })
      commit('SET_SITE_URL', data.site_url, { root: true })
      commit('ads/SET_ADS_COUNT', data.ads_count, { root: true })
      dispatch('updateUrls', null, { root: true })
      dispatch('auth/checkAuthentication', null, { root: true })
      dispatch('showCookiesPolicy')
      dispatch('initAction')
    }
  },
  resetClientInfo: ({ commit }) => {
    commit('SET_CURRENT_CLIENT_INFO', null)
  },
  setHomeActive: ({ commit }, page) => {
    commit('SET_HOME_ACTIVE', page)
  },
  showCookiesPolicy: ({ getters, commit }) => {
    const clientData = localStorage.getItem(getters.clientStorageId)
    if (clientData) {
      const data = JSON.parse(clientData)
      if (data.hideCookiesPolicy) {
        commit('SET_SHOW_COOKIES_POLICY', false)
      } else {
        commit('SET_SHOW_COOKIES_POLICY', getters.cookiesPolicy !== '')
      }
    } else {
      commit('SET_SHOW_COOKIES_POLICY', getters.cookiesPolicy !== '')
    }
  },
  hideCookiesPolicy: ({ getters, commit }) => {
    const clientData = localStorage.getItem(getters.clientStorageId)
    let data
    if (!clientData) {
      data = {
        id: getters.client,
        hideCookiesPolicy: true
      }
    } else {
      data = JSON.parse(clientData)
      data.hideCookiesPolicy = true
    }
    localStorage.setItem(getters.clientStorageId, JSON.stringify(data))
    commit('SET_SHOW_COOKIES_POLICY', false)
  },
  saveAction: ({ getters, commit }, payload) => {
    const clientData = localStorage.getItem(getters.clientStorageId)
    let data
    if (!clientData) {
      data = {
        id: getters.client
      }
    } else {
      data = JSON.parse(clientData)
    }
    if (payload) {
      data.action = payload
    } else if (data.action) {
      delete data.action
    }
    localStorage.setItem(getters.clientStorageId, JSON.stringify(data))
  },
  initAction: ({ getters, commit }) => {
    const clientData = localStorage.getItem(getters.clientStorageId)
    if (!clientData) {
      commit('SET_STORED_ACTION', null)
    } else {
      const data = JSON.parse(clientData)
      commit('SET_STORED_ACTION', data.action)
    }
  }
}
