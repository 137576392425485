import Vue from 'vue'
import VueRouter from 'vue-router'
import AdSelect from '@/components/ads/AdSelect'
import AdDetails from '@/components/ads/AdDetails'
import CartView from '@/views/CartView'
import ClientHomeView from '@/views/ClientHomeView'
import InfoAdsView from '@/views/InfoAdsView'
import MarketView from '@/views/MarketView'
import ProductsWithGiftsView from '@/views/ProductsWithGiftsView'
import UserHomeView from '@/views/UserHomeView'
import VouchersAdm from '@/components/vouchersAdm/VouchersAdm'
import VouchersView from '@/views/VouchersView'
import store from '@/store/index'
import HomeView from '@/views/HomeView.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import IframeView from '@/views/IframeView.vue'
import WelcomeView from '@/views/WelcomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home/:id/:idCategory?',
    name: 'home',
    component: HomeView
  },
  {
    path: '/ads-list/:id',
    name: 'ads',
    component: MarketView
  },
  {
    path: '/gifts-list/:id',
    name: 'gifts',
    component: ProductsWithGiftsView
  },
  {
    path: '/vouchers/:id',
    name: 'vouchers',
    component: VouchersView
  },
  {
    path: '/info/:id',
    name: 'info',
    component: InfoAdsView
  },
  {
    path: '/cart/:id/:action?/:message?',
    component: CartView
  },
  {
    path: '/client-home/:id/:page?',
    component: ClientHomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-home/:id/:page?',
    component: UserHomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ad-select/:client/:ad',
    component: AdSelect
  },
  {
    path: '/ad-details/:client/:ad/:section',
    component: AdDetails
  },
  {
    path: '/vouchers-adm/client/:id',
    component: VouchersAdm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/auth/reset_password/:client/:userId/:key',
    component: ResetPassword
  },
  {
    path: '/auth/send_again/:client',
    component: ResetPassword
  },
  {
    path: '/external/:client/:category',
    component: IframeView
  },
  {
    path: '/welcome/:id',
    name: 'welcome',
    component: WelcomeView
  },
  {
    path: '/:slug',
    component: HomeView
  }
  /* ,
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/!* webpackChunkName: "about" *!/ '../views/AboutView.vue')
  } */
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(function (to, from, next) {
  // console.log('invited: ', store.getters['auth/invited'], ' - registered: ', store.getters['auth/registered'])
  if (!store.getters.noAuth && store.getters['auth/exclusiveSite'] && (!store.getters['auth/invited'] && !store.getters['auth/registered'])) {
    window.location.replace('/')
  } else if (!store.getters.noAuth && to.meta && to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
    window.location.replace(store.getters.homeUrl)
  } else if (!store.getters.noAuth && to.meta && to.meta.requiresUnauth && store.getters['auth/isAuthenticated']) {
    window.location.replace(store.getters.homeUrl)
  } else {
    next()
  }
})

export default router
