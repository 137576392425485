export default {
  production: true,
  noAuth: false,
  testingUrl: 'https://quicknex.php5.docker',
  siteUrl: '',
  homeUrl: '#',
  showFilters: false,
  showListFilters: false,
  windowHeight: null,
  windowWidth: null,
  footerHeight: 0,
  navbarHeight: 0,
  siteName: 'Wokiu',
  language: 'es',
  translations: {},
  location: {
    longitude: 2.19073,
    latitude: 41.39625
  },
  sidebarWidth: 240,
  contentPadding: '1rem',
  activePage: '',
  exclusiveSite: false,
  sideBarActive: true,
  sideBarVisible: true,
  busy: false,
  infoWindowOpen: false,
  showMap: false,
  showCategories: true,
  isRouterAlive: true,
  summaryLength: 800,
  slug: '',
  theme: 'light',
  googleMapsKey: 'AIzaSyCfJ6XxVTP3RTVa4PrQR9B0KVf7i5IBHPw'
}
