import { render, staticRenderFns } from "./AdLink.vue?vue&type=template&id=664f0000&scoped=true&"
import script from "./AdLink.vue?vue&type=script&lang=js&"
export * from "./AdLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "664f0000",
  null
  
)

export default component.exports