<template>
  <div class="content-body">
    <page-title :title="translation('vouchers_administration')" icon="gift" :showFiltersIcon="true"/>
    <client-header />
    <general-status />
    <voucher-filters-bar :showStatus="false"/>
    <vouchers-list />
    <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
    <transition name="fade">
      <filters-panel v-show="showFilters" class="filters-panel"/>
    </transition>
    <div
      v-show="showButton"
      class="filter-toggler"
      :class="{ 'bg-dark text-light': theme === 'light', 'bg-light text-dark': theme === 'dark'}"
      @click="toggleFilters"
    >
      <font-awesome-icon icon="chevron-left"/>
      Filtrar
    </div>
    <reservation-modal />
    <validation-modal />
    <filters-modal />
  </div>
</template>

<script>
import VouchersList from '@/components/vouchersAdm/VouchersList'
import GeneralStatus from '@/components/vouchersAdm/GeneralStatus'
import ReservationModal from '@/components/vouchersAdm/ReservationModal'
import ValidationModal from '@/components/vouchersAdm/ValidationModal'
import ClientHeader from '@/components/vouchersAdm/ClientHeader'
import FiltersModal from '@/components/vouchersAdm/FiltersModal'
import FiltersPanel from '@/components/vouchersAdm/FiltersPanel'
import PageTitle from '@/components/common/PageTitle'
import InfiniteLoading from 'vue-infinite-loading'
import { mapActions, mapGetters } from 'vuex'
import VoucherFiltersBar from '@/components/vouchers/VoucherFiltersBar.vue'

export default {
  name: 'VouchersAdm',
  components: { VoucherFiltersBar, PageTitle, FiltersPanel, FiltersModal, ClientHeader, ValidationModal, ReservationModal, VouchersList, GeneralStatus, InfiniteLoading },
  data () {
    return {
      showButton: true
    }
  },
  methods: {
    ...mapActions(['toggleFiltersPanel', 'hideFiltersPanel']),
    ...mapActions('clients', ['initClient']),
    ...mapActions('vouchers', ['loadPage', 'initVouchers', 'setOnlyMyVouchers', 'clearFilters']),
    toggleFilters () {
      if (this.showModal) {
        this.$bvModal.show('filters-modal')
      } else {
        this.toggleFiltersPanel()
      }
    },
    infiniteHandler ($state) {
      this.loadPage()
        .then(data => {
          if (data.length > 0) {
            $state.loaded()
          } else {
            $state.complete()
          }
        })
    }
  },
  mounted () {
    this.initClient(this.$route.params.id)
    // console.log('Client - created: ', this.client)

    // this.setSiteUrl(window.location.protocol + '//' + window.location.hostname)
    this.hideFiltersPanel()
    this.clearFilters()
    this.setOnlyMyVouchers(false)
    this.initVouchers()
  },
  computed: {
    ...mapGetters(['translation', 'busy', 'showFilters', 'windowWidth', 'windowHeight', 'theme']),
    ...mapGetters('clients', ['client']),
    ...mapGetters('vouchers', ['infiniteId']),
    /* showButton () {
      return !this.showFilters
    }, */
    showModal () {
      return this.windowWidth <= 500 || this.windowHeight <= 724
    }
  }
}
</script>

<style lang="scss" scoped>
  .content-body {
    position: relative;
  }

  .filters-panel {
    top: 80px;
    right: 0;
    width: 500px;
    position: fixed;
    z-index: 1000;
  }

  .filter-toggler {
    position: fixed;
    top: 166px;
    right: -4px;
    padding: 12px;
    opacity: 0.6;
    color: #ffffff;
    //background-color: #88BD2F;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 500px) {
    .filters-panel {
      width: 100%;
    }

    .filter-toggler {
      top: 200px;
    }
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition: opacity 1s;
  }

  .fade-leave {

  }

  .fade-leave-active {
    transition: opacity 1s;
    opacity: 0;
  }

  .slide-enter {

  }

  .slide-enter-active {
    animation: slide-in 1s ease-out forwards;
  }

  .slide-leave {

  }

  .slide-leave-active {
    animation: slide-out 1s ease-out forwards;
  }

  @keyframes slide-out {
    from {
      transform: translateX(-500px);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slide-in {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-500px);
    }
  }
</style>
