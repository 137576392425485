export default {
  'SET_ROLE' (state, role) {
    state.role = role
  },
  'SET_INVITED' (state, invited) {
    state.invited = invited
  },
  'SET_REGISTERED' (state, registered) {
    state.registered = registered
  },
  'SET_ACCOUNT_TYPE' (state, accountType) {
    state.accountType = accountType
  },
  'SET_ACTIVATED' (state, activated) {
    state.activated = activated
  },
  'SET_USER_NAME' (state, name) {
    state.userName = name
  },
  'SET_EMAIL' (state, email) {
    state.email = email
  }
}
