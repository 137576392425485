<template>
  <b-col
    v-if="ad"
    :data-id="ad.id_ad"
    cols="6"
    sm="6"
    md="6"
    lg="4"
    xl="3"
    class="category-item package-item ribbon-item"
    :class="{ 'mb-2': isMobileView, 'mb-3': !isMobileView }"
  >
    <b-card
      no-body
      border-variant="secondary"
      :class="{ 'shadow-lg': hovering, 'text-center pt-0': isMobileView }"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false"
    >
      <b-card-body
        class="px-0 pb-0"
        :class="{ 'text-center pt-0': isMobileView}"
      >
        <ad-card-header :ad="ad"/>
          <!--<video-player2
          v-if="ad.video"
          :video="ad.video"
        />-->
        <video-player-item
          v-if="videoPlay"
          :video="ad.video"
        />
        <b-link :to="viewUrl">
          <b-img
            v-if="!videoPlay"
            :src="ad.preview"
            alt="" fluid
          />
        </b-link>
        <div class="p-3">
          <div
            v-if="parseInt(ad.featured)"
            class="sponsored-label"
            :style="customBandStyles"
          >
            {{ translation('featured') }}
          </div>
          <div class="pack-name">
            <h5 :class="{'text-left': !isMobileView, 'text-center': isMobileView}">
              {{ ad.name }}
              <small>
                <b-link :to="viewUrl">
                  +info
                </b-link>
              </small>
            </h5>
          </div>
          <price-block :ad="ad" :section="section" :inCard="true"/>
          <b-form-row v-if="ad.distance_friendly" class="mb-2">
            <b-col class="distance-label text-right">Distancia:</b-col>
            <b-col class="distance-label pl-0">{{ ad.distance_friendly }}</b-col>
          </b-form-row>
          <div v-if="showSummary">
            {{ ad.summary }}
          </div>
        </div>
        <b-button-group
          class="w-100 text-right card-actions"
        >
          <b-link
            v-if="!showBuyButton"
            class="btn view-btn pt-2"
            :to="viewUrl"
            :style="customBandStyles"
          >
            <font-awesome-icon icon="eye"/>
            {{ translation('view') }}
          </b-link>
          <b-link
            v-if="ad.voucher_send_url"
            class="btn view-btn pt-2"
            @click="send"
            :style="customBandStyles"
          >
            <font-awesome-icon icon="envelope"/>
            {{ translation('send') }}
          </b-link>
          <b-link
            v-if="ad.voucher_download_url"
            class="btn view-btn pt-2"
            @click="downloadVoucher"
            :style="customBandStyles"
          >
            <font-awesome-icon icon="download"/>
            {{ translation('download') }}
          </b-link>
          <add-cart-btn :ad="ad" :section="section" :inCard="true"/>
        </b-button-group>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import AdCardHeader from '@/components/ads/AdCardHeader'
import PriceBlock from '@/components/ads/PriceBlock'
import AddCartBtn from '@/components/cart/AddCartBtn'
import { mapActions, mapGetters } from 'vuex'
import VideoPlayerItem from '@/components/common/VideoPlayerItem.vue'

export default {
  name: 'AdCard',
  components: { VideoPlayerItem, AddCartBtn, PriceBlock, AdCardHeader },
  props: ['ad', 'section', 'observer'],
  data () {
    return {
      hovering: false
    }
  },
  computed: {
    ...mapGetters(['translation', 'language', 'siteUrl', 'isMobileView']),
    ...mapGetters('clients', ['customBandStyles', 'client']),
    ...mapGetters('ads', ['showPromoPrice', 'viewingAd']),
    ...mapGetters('auth', ['invited', 'isAuthenticated', 'isUser']),
    showSummary () {
      return this.ad.id_type === 'i'
    },
    viewUrl () {
      return `/ad-details/${this.client}/${this.ad.id_ad}/${this.section}`
      // return `${this.ad.view_url}/${this.language}/0/0/${this.section}`
    },
    videoPlay () {
      /* const newVar = this.ad.video && parseInt(this.ad.id_ad) === parseInt(this.viewingAd)
      console.log('id: ', this.ad.id_ad, ' - viewingAd: ', this.viewingAd, '- ', newVar) */
      return this.ad.video && parseInt(this.ad.id_ad) === parseInt(this.viewingAd)
    },
    showBuyButton () {
      return this.ad.canBeBought && (!this.invited || this.section === 'gifts')
    }
  },
  mounted () {
    this.observer.observe(this.$el)
  },
  methods: {
    ...mapActions('ads', ['setCurrentAd']),
    ...mapActions('vouchers', ['download', 'setDownloadId']),
    ...mapActions('auth', ['setAccountType']),
    authenticate () {
      this.setAccountType('private')
      this.$bvModal.show('auth-modal')
    },
    send () {
      if (this.isAuthenticated && this.isUser) {
        this.setCurrentAd(this.ad)
        this.$bvModal.show('voucher-send-modal')
      } else if (!this.isAuthenticated) {
        this.authenticate()
      }
    },
    downloadVoucher () {
      this.setDownloadId(null)
      if (this.isAuthenticated && this.isUser) {
        this.download(this.ad.voucher_download_url)
          .then(data => {
            if (data.success === 'true') {
              this.makeToast(data.heading, data.message, 'success')
            } else {
              this.makeToast(data.heading, data.message, 'danger')
            }
          })
      } else if (!this.isAuthenticated) {
        this.setDownloadId(this.ad.id_ad)
        this.authenticate()
      }
    }
  }
}
</script>

<style lang="scss">
  .distance-label {
    font-size: 0.9rem;
  }

  .sponsored-label {
    margin: -1rem -1rem 0.5rem -1rem;
    padding: 0.5rem 1rem;
    line-height: 1;
    text-align: center;
  }

  .view-btn {
    &:hover {
      opacity: 0.7;
    }
  }

  .btn-group {
    .btn {
      &:first-child {
        border-top-left-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 0;
      }
    }
  }

  .img-fluid {
    width: 100%;
  }
</style>
