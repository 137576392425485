<template>
  <b-container>
    <h1>¡Bienvenido a {{ clientName }}!</h1>
    <p>Estimado/a <b>{{ userName }}</b>:</p>

    <p>Gracias por registrarte. Estamos encantados de darte la bienvenida a nuestra plataforma.</p>

    <p>Para completar tu registro, por favor revisa tu correo electrónico: <b>{{ email }}</b>. Hemos enviado un enlace de confirmación que te permitirá activar tu cuenta y disfrutar de todas nuestras funcionalidades. Si no lo encuentras en tu bandeja de entrada, no olvides revisar la carpeta de correo no deseado.</p>

    <p>Ya estás a solo un paso de comenzar tu experiencia con nosotros. Si tienes alguna pregunta o necesitas asistencia, nuestro equipo de soporte está aquí para ayudarte.</p>

    <p>¡Gracias por confiar en {{ companyName }}! Esperamos que disfrutes de todo lo que tenemos para ofrecer.</p>

    <p>
      Saludos cordiales,<br/>
      El equipo de {{ companyName }}<br/>
      {{ clientName }}<br/>
    </p>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WelcomeView',
  computed: {
    ...mapGetters(['translation']),
    ...mapGetters('clients', ['clientName', 'companyName']),
    ...mapGetters('auth', ['userName', 'email'])
  },
  methods: {
    ...mapActions(['setSidebarVisibility']),
    ...mapActions('clients', ['initClient'])
  },
  created () {
    this.setSidebarVisibility(false)
    if (this.$route.params.id) {
      this.initClient(this.$route.params.id)
        .then(() => {
        })
    }
  }
}
</script>

<style scoped>

</style>
