export default {
  client: state => {
    return state.client
  },
  clientInfo: state => {
    return state.clientInfo
  },
  clientName: state => {
    return state.clientName
  },
  companyName: state => {
    return state.clientInfo && state.clientInfo.owner_info && state.clientInfo.owner_info.commercial_name ? state.clientInfo.owner_info.commercial_name : null
  },
  homeInfo: state => {
    return state.clientInfo && state.clientInfo.home_page ? state.clientInfo.home_page : null
  },
  homeIframeURL: state => {
    return state.clientInfo && state.clientInfo.home_page && state.clientInfo.home_page.item_type === 'iframe_category' && state.clientInfo.home_page.external_url ? state.clientInfo.home_page.external_url : ''
  },
  clients: state => {
    return state.clients
  },
  getClientById: (state) => (id) => {
    return state.clients.find(client => client.id === id)
  },
  customBandStyles: state => {
    return state.clientInfo ? state.clientInfo.band_style : ''
  },
  inverseBandStyles: state => {
    return state.clientInfo ? state.clientInfo.inverse_band_style : ''
  },
  borderStyles: state => {
    return state.clientInfo ? state.clientInfo.border_style : ''
  },
  linkStyles: state => {
    return state.clientInfo ? { color: state.clientInfo.link_color } : {}
  },
  buttonStyles: (state) => (inverse) => {
    if (state.clientInfo) {
      return inverse ? state.clientInfo.inverse_band_style : state.clientInfo.band_style
    }
    return ''
  },
  terms: state => {
    return state.clientInfo && state.clientInfo.terms ? state.clientInfo.terms : ''
  },
  privacy: state => {
    return state.clientInfo && state.clientInfo.privacy ? state.clientInfo.privacy : ''
  },
  refundPolicy: state => {
    return state.clientInfo && state.clientInfo.refund_policy ? state.clientInfo.refund_policy : ''
  },
  cookiesPolicy: state => {
    return state.clientInfo && state.clientInfo.cookies_policy ? state.clientInfo.cookies_policy : ''
  },
  showCookiesPolicy: state => {
    return state.showCookiesPolicy
  },
  storedAction: state => {
    return state.storedAction
  },
  homeActive: state => {
    return state.homeActive
  },
  clientStorageId: (state) => {
    return `client-${state.client}`
  },
  categories: state => {
    return state.categories
  },
  categoryById: (state) => (id) => {
    return state.categories.find(category => parseInt(category.id_category) === parseInt(id))
  },
  languages: state => {
    return state.languages
  },
  defaultPage: state => {
    if (state.clientInfo) {
      if (state.clientInfo.home_page) {
        return `/home/${state.client}`
      }
      if (state.clientInfo.ads_count.vouchers > 0) {
        return `/vouchers/${state.client}`
      }
      if (state.clientInfo.ads_count.gifts > 0) {
        return `/gifts-list/${state.client}`
      }
      if (state.clientInfo.ads_count.marketplace > 0) {
        return `/ads-list/${state.client}`
      }
      if (state.clientInfo.ads_count.info > 0) {
        return `/info/${state.client}`
      }
    }
    return ''
  }
}
