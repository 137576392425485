<template>
  <b-navbar
    v-if="sideBarActive"
    id="home-menu-bar"
    class="d-flex d-md-none px-0"
  >
    <b-nav
      v-if="isReceptionManager"
    >
      <home-menu-link section="collaboration" title="collaboration" icon="handshake-simple"/>
      <home-menu-link section="vouchers" title="vouchers" icon="gifts"/>
      <home-menu-link section="sales" title="sales" icon="sack-dollar"/>
      <home-menu-link section="purchases" title="purchases" icon="sack-dollar"/>
      <home-menu-link section="vouchersAdm" title="vouchers_administration" icon="gift"/>
      <home-menu-link icon="calendar-check" title="validate" :action="validate"/>
      <home-menu-link icon="calendar-alt" title="reserve" :action="reserve"/>
      <b-nav-item
        href="/admin"
        :title="translation('client_panel')"
      >
        <font-awesome-icon icon="cogs"/>
      </b-nav-item>
    </b-nav>
    <b-nav
      v-if="isReception"
      class="mb-3"
    >
      <home-menu-link section="vouchers" title="vouchers" icon="gifts"/>
      <home-menu-link section="vouchersAdm" title="vouchers_administration" icon="gift"/>
      <home-menu-link icon="calendar-check" title="validate" :action="validate"/>
      <home-menu-link icon="calendar-alt" title="reserve" :action="reserve"/>
    </b-nav>
    <b-nav v-if="isUser">
      <home-menu-link section="vouchers" title="vouchers" icon="gift"/>
      <home-menu-link section="products" title="products_services" icon="gifts"/>
      <home-menu-link section="purchases" title="purchases" icon="cart-plus"/>
    </b-nav>
    <b-nav
      class="ml-auto"
    >
      <b-nav-item
        :href="`/userauth/logout/${client}`"
        :title="translation('logout')"
      >
        <font-awesome-icon icon="sign-out"/>
      </b-nav-item>
    </b-nav>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HomeMenuLink from '@/components/common/HomeMenuLink.vue'

export default {
  name: 'HomeMenuBar',
  components: { HomeMenuLink },
  computed: {
    ...mapGetters(['translation', 'activePage', 'sideBarActive', 'sideBarVisible']),
    ...mapGetters('auth', ['isUser', 'isReceptionManager', 'isReception']),
    ...mapGetters('clients', ['client', 'customBandStyles'])
  },
  methods: {
    ...mapActions(['setActivePage']),
    validate () {
      this.$bvModal.show('product-validation-modal')
    },
    reserve () {
      this.$bvModal.show('product-reservation-modal')
    },
    buttonStyles (section) {
      return this.isActiveButton(section) ? this.customBandStyles : ''
    },
    isActiveButton (section) {
      return section === this.activePage
    }
  }
}
</script>

<style lang="scss" scoped>
  .nav-item {
    height: 40px;
  }

  a {
    color: black;
  }
</style>
