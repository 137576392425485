export default {
  role: state => {
    return state.role
  },
  userName: state => {
    return state.userName
  },
  email: state => {
    return state.email
  },
  isUser: state => {
    return state.role === 'user' || state.role === 'wall_user'
  },
  isReceptionManager: state => {
    return state.role === 'reception_manager'
  },
  isReception: state => {
    return state.role === 'reception'
  },
  isAuthenticated: state => {
    return state.role !== ''
  },
  invited: state => {
    return state.invited
  },
  registered: state => {
    return state.registered
  },
  accountType: state => {
    return state.accountType
  },
  showProfessionalFields: state => {
    return state.accountType === 'professional'
  },
  activated: state => {
    return state.activated
  },
  isPasswordValid: () => (password) => {
    return password.match(/^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$/) ||
      password.match(/^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9]).{8,}$/)
    /* console.log('password: ', password)
    console.log('0 - ', password.match(/^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9])(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).{8,}$/))
    console.log('1 - ', password.match(/^(?=[^A-Z\n]*[A-Z])(?=[^a-z\n]*[a-z])(?=[^0-9\n]*[0-9]).{8,}$/))
    console.log('2 - ', password.match(/^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/))
    console.log('3 - ', password.match(/^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[^*.$@#%&+\-_]*|[a-zA-Z0-9]*)$/))
    return !password.match(/^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/) || !password.match(/^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[^*.$@#%&+\-_]*|[a-zA-Z0-9]*)$/) */
  }
}
