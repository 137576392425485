export default {
  'SET_CLIENT' (state, client) {
    state.client = client
  },
  'SET_CURRENT_CLIENT_INFO' (state, info) {
    state.clientInfo = info
  },
  'SET_NAME' (state, name) {
    state.clientName = name
  },
  'SET_COMPANY_NAME' (state, name) {
    state.companyName = name
  },
  'SET_CATEGORIES' (state, categories) {
    state.categories = categories
  },
  'SET_LANGUAGES' (state, languages) {
    state.languages = languages
  },
  'SET_CLIENTS' (state, clients) {
    state.clients = clients
  },
  'SET_HOME_ACTIVE' (state, page) {
    state.homeActive = page
  },
  'SET_SHOW_COOKIES_POLICY' (state, value) {
    state.showCookiesPolicy = value
  },
  'SET_STORED_ACTION' (state, value) {
    state.storedAction = value
  }
}
